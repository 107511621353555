import { useEffect } from 'react'
import PropTypes from 'prop-types'

function RedirectURL({ children }) {
  useEffect(() => {
    if (window.location.origin.includes('www.omginsite.com')) {
      window.location.href = `https://omginsite.com/${window.location.hash.replace(
        'http://omginsite.com',
        '',
      )}`
    }
  }, [])

  return <>{children}</>
}

RedirectURL.propTypes = {
  children: PropTypes.object,
}

export default RedirectURL
